
import { Vue, Component } from 'vue-property-decorator';
import VueSlickCarousel from 'vue-slick-carousel';
import { getChallengeDetail, joinChallenge, getChallengeRecordList } from '@/api/challenge';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

@Component({
  components: {
    VueSlickCarousel,
  },
})
export default class extends Vue {
  async mounted() {
		await this.getChallengeDetail();
	}

	private dueOverFlag = false;

  private loading = true;

	private apiUrl = process.env.VUE_APP_COMMON_API;

  private set3 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  };

  private listQuery = {
		challengeUid: this.$route.params.challengeUid,
    myFlag: false,
	}

	private challenge: any = {};

  private async getChallengeDetail() {
		await getChallengeDetail(this.$route.params.challengeUid).then((res) => {
			this.challenge = res.data;
      if (this.challenge.challengeUser !== null) {
        this.dueOverFlag = new Date() >= new Date(this.challenge.challengeUser.dueDate);
      }
		}).catch(() => {
			this.$message.warning('챌린지를 조회하는데 실패했습니다.');
		});
    this.set3 = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      };
		this.loading = false;
	}

  private async getChallengeRecordList(type: string) {
    this.loading = true;
    if (type === 'my') this.listQuery.myFlag = true;
    else this.listQuery.myFlag = false;
		await getChallengeRecordList(this.listQuery).then((res) => {
			this.challenge.recordList = res.data;
		}).catch(() => {
			this.$message.warning('챌린지 기록을 조회하는데 실패했습니다.');
		});
		this.loading = false;
	}

  private async joinChallenge() {
    this.$confirm('정말 챌린지에 참여하시겠습니까?').then(() => {
      joinChallenge({ challengeUid: this.$route.params.challengeUid }).then(() => {
        this.$message.success('챌린지에 참여 하셨습니다.');
        this.$router.push({ name: 'Challenge', params: { boardUid: this.$route.params.boardUid } });
      }).catch((error) => {
        this.$message.error(error.response.data.message || '챌린지에 참여 하는데 실패했습니다.');
      });
    });
	}

  private RecordEditHandler(uid: string) {
    if (this.listQuery.myFlag) {
      this.$router.push({ name: 'ChallengeWrite', params: { challengeUid: this.$route.params.challengeUid, uid } });
    }
  }

  private convertedText(text: string) {
      return text.replace(/\n/g, '</br>');
  }

  private linesWithLiTags(text: string) {
      return text.split('\n');
  }
}
